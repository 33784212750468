// src/App.jsx
import React, { useEffect, useState } from 'react';
import Carousel from '../../components/Carousel';
import WOW from 'wowjs';
import 'animate.css';
import { getMedia } from '../../utils/get-media.ts';

const Marketing = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [slides, setSlides] = useState([]);
    const [etiqueta, setEtiqueta] = useState({});
    const [apartados, setApartados] = useState([]);
    const [portafolio, setPortafolio] = useState({});

    useEffect(() => {
        const fetchApartados = async () => {
            try {
                const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/servicio/149');
                const data = await response.json();

                // Si hay apartados en el ACF, los guardamos
                if (data.acf) {
                    setData(data.acf);
                }

                // SLIDES
                if (data.acf?.slides) {
                    const getEtiqueta = async () => {
                        const etiqueta = data.acf.slides.etiqueta;
                        const icono = await getMedia(etiqueta.icono);
                        return {
                            icono: icono,
                            texto: etiqueta.texto
                        };
                    }
                    setEtiqueta(await getEtiqueta());

                    const slidesData = await Promise.all(data.acf.slides.lista_de_slides.map(async (slide) => {
                        const imageId = slide.fondo;
                        let imageUrl = null;

                        if (imageId) {
                            imageUrl = await getMedia(imageId);
                        }

                        return {
                            title: slide.titulo,
                            text: slide.texto,
                            imageUrl: imageUrl || '../images/default.png', // Imagen por defecto si no hay imagen
                        };
                    }));

                    setSlides(slidesData);
                }

                // APARTADOS
                if (data.acf?.apartados) {
                    const apartadosData = await Promise.all(data.acf.apartados.map(async (apartado) => {
                        const imageId = apartado.imagen;
                        let imageUrl = null;
                        let videoUrl = null;
                    
                        if (imageId) {
                            imageUrl = await getMedia(imageId);
                        }
                    
                        // Verificar si agregar_video está en true y obtener el video
                        if (apartado.video?.agregar_video && apartado.video.video) {
                            videoUrl = await getMedia(apartado.video.video);
                        }
                    
                        return {
                            ...apartado,
                            imageUrl: imageUrl || '../images/default.png',
                            videoUrl: videoUrl, // URL del video si agregar_video es true
                        };
                    }));
                    
                    setApartados(apartadosData);
                }

                // PORTAFOLIO
                if (data.acf?.portafolio) {
                    // Obtener el título del portafolio
                    const portafolioTitle = data.acf.portafolio.titulo;
                
                    // Obtener los datos de los elementos del portafolio
                    const portafolioData = await Promise.all(data.acf.portafolio.elementos.map(async (portafolio) => {
                        const imageId = portafolio.imagen;
                        let imageUrl = null;
                
                        if (imageId) {
                            imageUrl = await getMedia(imageId); // Función que recupera la URL de la imagen
                        }
                
                        return {
                            ...portafolio,
                            imageUrl: imageUrl || '../images/default.png', // Imagen por defecto si no hay imagen
                        };
                    }));
                
                    // Obtener los datos del botón
                    const portafolioButton = data.acf.portafolio.boton || { texto: '', link: '#' }; // Si no existe el botón, usa valores predeterminados
                
                    // Actualizar el estado con los datos obtenidos
                    setPortafolio({
                        title: portafolioTitle,
                        items: portafolioData,
                        button: portafolioButton,
                    });
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchApartados();
    }, []);

    useEffect(() => {
        const handleLoad = () => {
            setTimeout(() => {
                new WOW.WOW({
                    live: true, // Mantener activo el monitoreo en vivo
                }).init();
            }, 2000); // Pausa de 2 segundos antes de iniciar WOW.js

            setTimeout(() => {
                setLoading(false); // Quita el loading después de 1 segundo
            }, 2000);
        };
    
        // Escuchar el evento de carga completa
        window.addEventListener('load', handleLoad);
    
        // Limpiar el listener cuando el componente se desmonte
        return () => {
            window.removeEventListener('load', handleLoad);
        };

    }, []);  

    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (itemId) => {
        if (selectedItem === itemId) {
            setSelectedItem(null);  // Deselecciona el item si ya está seleccionado
        } else {
            setSelectedItem(itemId);  // Selecciona el item si no está seleccionado
        }
    };

    if (loading) {
        return (
            <div className='w-full h-full bg-black fixed top-0 z-[999999999] flex flex-col justify-center items-center'>
                <img className='w-full h-[44px] object-contain' src='../images/icons/logo.svg' alt="" />
            </div>
        );
    }

    return (
        <div className='overflow-x-hidden w-full'>
            <div className='bg-black'>
                <Carousel slides={slides} color={'#FF73B9'} colorDegradado={'#9C95E1'} />
                <div className='relative z-[99999] w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto md:mb-[-100px] mb-[-70px]'>
                    <div className='absolute bottom-[30px]'>
                        <p className='text-white flex items-center gap-2 p-4 md:p-5 pl-0 max-md:text-[10px]'>
                            <img className='h-[30px] md:h-[40px]' src={etiqueta.icono} alt="" /> <span dangerouslySetInnerHTML={{ __html: etiqueta.texto }} />
                        </p>
                    </div>
                </div>
                <div className='clip-diagonal-pink z-[99999]'>

                </div>
                <div className='clip-diagonal-white'>

                </div>
            </div>
            <div className='py-[20px] lg:py-[60px]'>
                <div className='w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto'>
                    <div className="text-center mb-[70px] mt-[50px] md:mb-[160px] md:mt-[120px]">
                        <h1 className="text-[22px] md:text-[32px] uppercase relative w-[350px] max-w-full mx-auto wow animate__animated animate__fadeInDown" data-wow-duration="2s">
                            <img src="../images/ESSENCE-icon.png" alt="Essence" className="w-[30px] md:w-[80px] absolute translate-x-[10px] md:left-[-100px] top-[-20px] md:top-[-50px]" /> <span className='[&_strong]:text-[#FF73B9] ' dangerouslySetInnerHTML={{ __html: data.titulos_apartados.titulo }} />
                        </h1>
                        <p className="text-sm md:text-lg mt-4 pt-6 w-[875px] max-w-full mx-auto wow animate__animated animate__fadeInUp" data-wow-duration="2s" dangerouslySetInnerHTML={{ __html: data.titulos_apartados.descripcion }} />
                    </div>
                    {apartados?.map((apartado, index) => (
                        <>
                            {index % 2 === 0 ? (
                                <section key={index} className="flex flex-col md:flex-row items-center my-10">
                                    <div className="flex-1 flex justify-center relative before:absolute before:bottom-[-10px] before:left-[-15px] before:w-[30px] before:rounded-tr-[2px] before:bg-white before:h-1/2 after:content-[''] after:h-[30px] after:w-[30px] after:rotate-45 after:bg-white after:left-[-22px] after:top-[48%] after:absolute wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                                        {apartado.videoUrl ? (
                                            <video 
                                                src={apartado.videoUrl} 
                                                width="100%" 
                                                height="auto" 
                                                loop 
                                                muted 
                                                autoPlay 
                                                playsInline 
                                                className="w-full h-auto rounded-[15px]"
                                            >
                                                Tu navegador no soporta la etiqueta de video.
                                            </video>
                                        ) : (
                                            <img src={apartado.imageUrl} alt={apartado?.titulo} className="w-full h-auto rounded-[15px]" />
                                        )}
                                    </div>
                                    <div className="max-w-full flex-1 flex flex-col justify-center mt-6 md:mt-0 md:ml-8 wow animate__animated animate__fadeInRight" data-wow-duration="2s">
                                        <h2 className="text-lg md:text-[32px] leading-[24px] md:leading-[32px] font-medium uppercase [&_strong]:text-[#FF73B9]" dangerouslySetInnerHTML={{ __html: apartado.titulo }} />
                                        <div className="flex flex-wrap md:flex-row gap-3 my-3">
                                            {apartado.tags?.length > 0 && apartado.tags.map((tag, index) => (
                                                <span key={index} className="bg-[#D7D7D7] px-2 py-1">{tag.tag}</span>
                                            ))}
                                        </div>
                                        <p className="my-4 text-[14px]" dangerouslySetInnerHTML={{ __html: apartado.descripcion }} />
                                    </div>
                                </section>
                            ) : (
                                <section key={index} className="flex flex-col-reverse md:flex-row items-center my-10 lg:my-20">
                                    <div className="max-w-full flex-1 flex flex-col justify-center mt-6 md:mt-0 md:mr-8  wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                                        <h2 className="text-lg md:text-[32px] leading-[24px] md:leading-[32px] font-medium uppercase [&_strong]:text-[#FF73B9]" dangerouslySetInnerHTML={{ __html: apartado.titulo }} />
                                        <div className="flex flex-wrap md:flex-row gap-3 my-3">
                                            {apartado.tags?.length > 0 && apartado.tags.map((tag, index) => (
                                                <span key={index} className="bg-[#D7D7D7] px-2 py-1">{tag.tag}</span>
                                            ))}
                                        </div>
                                        <p className="my-4 text-[14px]" dangerouslySetInnerHTML={{ __html: apartado.descripcion }} />
                                    </div>
                                    <div className="flex-1 flex justify-center relative before:absolute before:bottom-[-10px] before:right-[-15px] before:w-[30px] before:rounded-tr-[2px] before:bg-white before:h-1/2 after:content-[''] after:h-[30px] after:w-[30px] after:rotate-45 after:bg-white after:right-[-22px] after:top-[48%] after:absolute wow animate__animated animate__fadeInRight" data-wow-duration="2s">
                                        {apartado.videoUrl ? (
                                            <video 
                                                src={apartado.videoUrl} 
                                                width="100%" 
                                                height="auto" 
                                                loop 
                                                muted 
                                                autoPlay 
                                                playsInline 
                                                className="w-full h-auto rounded-[15px]"
                                            >
                                                Tu navegador no soporta la etiqueta de video.
                                            </video>
                                        ) : (
                                            <img src={apartado.imageUrl} alt={apartado?.titulo} className="w-full h-auto rounded-[15px]" />
                                        )}
                                    </div>
                                </section>
                            )}
                        </>
                    ))}
                </div>
            </div>
            <div className='w-[1200px] max-w-full mx-auto mb-8 max-md:px-10'>
                <div className=''>
                    <h2 className="text-lg md:text-[32px] leading-[24px] md:leading-[32px] font-medium uppercase [&_strong]:text-[#FF73B9]" dangerouslySetInnerHTML={{ __html: portafolio.title}} />
                </div>
                <div className='w-full max-md:overflow-x-scroll relative'>
                    <div className="gallery-container mb-10">
                        {portafolio.items?.map((item, index) => (
                            <div
                                key={index}
                                className={`gallery-item  ${selectedItem === index ? 'active' : ''}`}
                                onClick={() => handleItemClick(index)}
                            >
                                <img src={item.imageUrl} alt={item.titulo} className="gallery-image" />
                                {selectedItem === index && (
                                    <div className="gallery-info">
                                        <h3>{item.titulo}</h3>
                                        <p>{item.texto}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='w-[1200px] max-w-full mx-auto mb-20 flex justify-center'>
                    <a href={portafolio.button.link} className="bg-[#383838]  text-white font-medium px-4 py-2" >
                        {portafolio.button.texto}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Marketing;