// src/App.jsx
import React, { useEffect, useState } from 'react';
import Carousel from '../../components/Carousel';
import WOW from 'wowjs';
import 'animate.css';
import { getMedia } from '../../utils/get-media.ts';

const Tour = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [slides, setSlides] = useState([]);
    const [etiqueta, setEtiqueta] = useState({});
    const [apartados, setApartados] = useState([]);
    const [modelado3d, setModelado3d] = useState([]);

    useEffect(() => {
        const fetchApartados = async () => {
            try {
                const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/servicio/140');
                const data = await response.json();

                // Si hay apartados en el ACF, los guardamos
                if (data.acf) {
                    setData(data.acf);
                }

                // SLIDES
                if (data.acf?.slides) {
                    const getEtiqueta = async () => {
                        const etiqueta = data.acf.slides.etiqueta;
                        const icono = await getMedia(etiqueta.icono);
                        return {
                            icono: icono,
                            texto: etiqueta.texto
                        };
                    }
                    setEtiqueta(await getEtiqueta());

                    const slidesData = await Promise.all(data.acf.slides.lista_de_slides.map(async (slide) => {
                        const imageId = slide.fondo;
                        let imageUrl = null;

                        if (imageId) {
                            imageUrl = await getMedia(imageId);
                        }

                        return {
                            title: slide.titulo,
                            text: slide.texto,
                            imageUrl: imageUrl, // Imagen por defecto si no hay imagen
                        };
                    }));

                    setSlides(slidesData);
                }

                // CATEGORIAS
                if (data.acf?.apartados) {
                    const apartadosData = await Promise.all(data.acf.apartados.map(async (apartado) => {
                        const imageId = apartado.imagen;
                        let imageUrl = null;
                        let videoUrl = null;
                    
                        if (imageId) {
                            imageUrl = await getMedia(imageId);
                        }
                    
                        // Verificar si agregar_video está en true y obtener el video
                        if (apartado.video?.agregar_video && apartado.video.video) {
                            videoUrl = await getMedia(apartado.video.video);
                        }
                    
                        return {
                            ...apartado,
                            imageUrl: imageUrl || '../images/default.png',
                            videoUrl: videoUrl, // URL del video si agregar_video es true
                        };
                    }));
                    
                    setApartados(apartadosData);
                }

                // MODELADO 3D
                if (data.acf?.modelado_3d?.imagenes_del_grid) {
                    const modelado3dData = await Promise.all(data.acf.modelado_3d?.imagenes_del_grid.map(async (modelado) => {
                        const imageId = modelado.imagen;
                        let imageUrl = null;

                        if (imageId) {
                            imageUrl = await getMedia(imageId);
                        }

                        return {
                            ...modelado,
                            imageUrl: imageUrl, // Imagen por defecto si no hay imagen
                        };
                    }));

                    setModelado3d(modelado3dData);
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchApartados();
    }, []);

    useEffect(() => {
        const handleLoad = () => {
            setTimeout(() => {
                new WOW.WOW({
                    live: true, // Mantener activo el monitoreo en vivo
                }).init();
            }, 2000); // Pausa de 2 segundos antes de iniciar WOW.js
        };
    
        // Escuchar el evento de carga completa
        window.addEventListener('load', handleLoad);
    
        // Limpiar el listener cuando el componente se desmonte
        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []); 

    if (loading) {
        return (
            <div className='w-full h-full bg-black fixed top-0 z-[999999999] flex flex-col justify-center items-center'>
                <img className='w-full h-[44px] object-contain' src='../images/icons/logo.svg' alt="" />
            </div>
        );
    }

    return (
        <div>
            <div className='bg-black'>
                <Carousel slides={slides} color={'#8CD8E5'} colorDegradado={'#196B75'} />
                <div className='relative z-[99999] w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto md:mb-[-100px] mb-[-70px]'>
                    <div className='absolute bottom-[30px]'>
                        <p className='text-white flex items-center gap-2 p-4 md:p-5 pl-0 max-md:text-[10px]'>
                            <img className='h-[30px] md:h-[40px]' src={etiqueta.icono} alt="" /> {etiqueta.texto}
                        </p>
                    </div>
                </div>
                <div className='clip-diagonal-turquoise z-[99999]'>

                </div>
                <div className='clip-diagonal-white'>

                </div>
            </div>
            <div className='py-[20px] lg:py-[60px]'>
                <div className='w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto'>
                    <div className="text-center mb-20">
                        <h1 className={`text-[22px] md:text-[32px] uppercase relative w-[350px] [&_strong]:text-[#8cd8e5] max-w-full mx-auto wow animate__animated animate__fadeInDown`} data-wow-duration="2s" dangerouslySetInnerHTML={{ __html: data.nuestros_servicios?.titulo }} />
                        <p className="text-sm md:text-lg mt-4 pt-6 font-bold w-[875px] max-w-full mx-auto wow animate__animated animate__fadeInUp" data-wow-duration="2s" dangerouslySetInnerHTML={{ __html: data.nuestros_servicios?.descripcion }} />
                    </div>
                    {apartados.map((apartado, index) => (
                        <>
                            {index % 2 === 0 ? (
                                <section key={index} className="flex flex-col md:flex-row items-center my-10">
                                    <div className="max-w-full flex-1 flex flex-col justify-center mt-6 md:mt-0 md:mr-8 wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                                        <h2 className="text-lg md:text-[32px] leading-[24px] md:leading-[32px] font-medium uppercase [&_strong]:text-[#8cd8e5] " dangerouslySetInnerHTML={{ __html: apartado?.titulo }} />
                                        <div className="flex flex-wrap md:flex-row gap-3">
                                            {apartado.tags?.length > 0 && apartado.tags.map((tag, index) => (
                                                tag?.tag && <span key={index} className="my-3 bg-[#D7D7D7] px-2 py-1">{tag?.tag}</span>
                                            ))}
                                        </div>
                                        <p className="my-4 text-[14px]" dangerouslySetInnerHTML={{ __html: apartado?.descripcion }} />
                                    </div>
                                    <div className="flex-1 flex justify-center relative before:absolute before:bottom-[-10px] before:right-[-15px] before:w-[30px] before:rounded-tr-[2px] before:bg-white before:h-1/2 after:content-[''] after:h-[30px] after:w-[30px] after:rotate-45 after:bg-white after:right-[-22px] after:top-[48%] after:absolute wow animate__animated animate__fadeInRight" data-wow-duration="2s">
                                        {apartado.videoUrl ? (
                                            <video 
                                                src={apartado.videoUrl} 
                                                width="100%" 
                                                height="auto" 
                                                loop 
                                                muted 
                                                autoPlay 
                                                playsInline 
                                                className="w-full h-auto rounded-[15px]"
                                            >
                                                Tu navegador no soporta la etiqueta de video.
                                            </video>
                                        ) : (
                                            <img src={apartado.imageUrl} alt={apartado?.titulo} className="w-full h-auto rounded-[15px]" />
                                        )}
                                    </div>
                                </section>
                            ) : (
                                <section key={index} className="flex flex-col-reverse md:flex-row items-center my-10 lg:my-20">
                                    <div className="flex-1 flex justify-center relative before:absolute before:bottom-[-10px] before:left-[-15px] before:w-[30px] before:rounded-tr-[2px] before:bg-white before:h-1/2 after:content-[''] after:h-[30px] after:w-[30px] after:rotate-45 after:bg-white after:left-[-22px] after:top-[48%] after:absolute wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                                        {apartado.videoUrl ? (
                                            <video 
                                                src={apartado.videoUrl} 
                                                width="100%" 
                                                height="auto" 
                                                loop 
                                                muted 
                                                autoPlay 
                                                playsInline 
                                                className="w-full h-auto rounded-[15px]"
                                            >
                                                Tu navegador no soporta la etiqueta de video.
                                            </video>
                                        ) : (
                                            <img src={apartado.imageUrl} alt={apartado?.titulo} className="w-full h-auto rounded-[15px]" />
                                        )}
                                    </div>
                                    <div className="max-w-full flex-1 flex flex-col justify-center mt-6 md:mt-0 md:ml-8 wow animate__animated animate__fadeInRight" data-wow-duration="2s">
                                        <h2 className="text-lg md:text-[32px] leading-[24px] md:leading-[32px] font-medium uppercase [&_strong]:text-[#8cd8e5] " dangerouslySetInnerHTML={{ __html: apartado?.titulo }} />

                                        {apartado.tags?.length > 0 && (
                                            <div className="flex flex-wrap md:flex-row gap-3">
                                                {apartado.tags?.map((tag, index) => (
                                                    tag?.tag && <span key={index} className="my-3 bg-[#D7D7D7] px-2 py-1">{tag?.tag}</span>
                                                ))}
                                            </div>
                                        )}
                                        <p className="my-4 text-[14px]" dangerouslySetInnerHTML={{ __html: apartado?.descripcion }} />
                                    </div>
                                </section>
                            )}
                        </>
                    ))}

                    <div className="w-[1200px] max-w-full mx-auto py-10">
                        {/* Título y descripción */}
                        <div className="w-full text-center mb-10 flex max-md:flex-col justify-between items-center">
                            <h1 className="text-4xl font-medium text-left w-[266px] flex gap-3 [&_strong]:text-[#8cd8e5] " dangerouslySetInnerHTML={{ __html: data.modelado_3d?.titulo }} />
                            <p className="mt-2 text-gray-500 text-right w-[496px] max-w-full" dangerouslySetInnerHTML={{ __html: data.modelado_3d?.descripcion }} />
                        </div>

                        {/* Grid de imágenes */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className="row-span-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="row-span-2 col-span-2 h-[324px] relative group">
                                    <img src={modelado3d[0].imageUrl} className='object-cover w-full h-full wow animate__animated animate__fadeIn' data-wow-duration="3s" alt={modelado3d[0].titulo} />
                                    <p className='bg-black px-3 py-1 text-white w-auto absolute top-0 left-0 group-hover:opacity-100 opacity-0 transition-all duration-300'>{modelado3d[0].tag}</p>
                                </div>
                                <div className="row-span-2 col-span-2 h-[324px] group relative">
                                    <img src={modelado3d[1].imageUrl} className='object-cover w-full h-full wow animate__animated animate__fadeIn' data-wow-duration="3s" alt={modelado3d[1].titulo} />
                                    <p className='bg-black px-3 py-1 text-white w-auto absolute top-0 left-0 group-hover:opacity-100 opacity-0 transition-all duration-300'>{modelado3d[1].tag}</p>
                                </div>
                            </div>
                            <div className="row-span-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="row-span-1 h-[156px] group relative">
                                    <img src={modelado3d[2].imageUrl} className='object-cover w-full h-full wow animate__animated animate__fadeIn' data-wow-duration="3s" alt={modelado3d[2].titulo} />
                                    <p className='bg-black px-3 py-1 text-white w-auto absolute top-0 left-0 group-hover:opacity-100 opacity-0 transition-all duration-300'>{modelado3d[2].tag}</p>
                                </div>
                                <div className="row-span-1 h-[156px] group relative">
                                    <img src={modelado3d[3].imageUrl} className='object-cover w-full h-full wow animate__animated animate__fadeIn' data-wow-duration="3s" alt={modelado3d[3].titulo} />
                                    <p className='bg-black px-3 py-1 text-white w-auto absolute top-0 left-0 group-hover:opacity-100 opacity-0 transition-all duration-300'>{modelado3d[3].tag}</p> 
                                </div>
                                <div className="row-span-2 col-span-2 h-[324px] bg-gray-300 group relative">
                                    <img src={modelado3d[4].imageUrl} className='object-cover w-full h-full wow animate__animated animate__fadeIn' data-wow-duration="3s" alt={modelado3d[4].titulo} />
                                    <p className='bg-black px-3 py-1 text-white w-auto absolute top-0 left-0 group-hover:opacity-100 opacity-0 transition-all duration-300'>{modelado3d[4].tag}</p>
                                </div>
                                <div className="row-span-1 h-[156px] group relative">
                                    <img src={modelado3d[5].imageUrl} className='object-cover w-full h-fullwow animate__animated animate__fadeIn' data-wow-duration="3s" alt={modelado3d[5].titulo} />
                                    <p className='bg-black px-3 py-1 text-white w-auto absolute top-0 left-0 group-hover:opacity-100 opacity-0 transition-all duration-300'>{modelado3d[5].tag}</p>
                                </div>
                                <div className="row-span-1 h-[156px] group relative">
                                    <img src={modelado3d[6].imageUrl} className='object-cover w-full h-fullwow animate__animated animate__fadeIn' data-wow-duration="3s" alt={modelado3d[6].titulo} />
                                    <p className='bg-black px-3 py-1 text-white w-auto absolute top-0 left-0 group-hover:opacity-100 opacity-0 transition-all duration-300'>{modelado3d[6].tag}</p>
                                </div>
                            </div>
                            <div className="row-span-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="row-span-2 col-span-2 h-[324px] group relative">
                                    <img src={modelado3d[7].imageUrl} className='object-cover w-full h-full wow animate__animated animate__fadeIn' data-wow-duration="3s" alt={modelado3d[7].titulo} />
                                    <p className='bg-black px-3 py-1 text-white w-auto absolute top-0 left-0 group-hover:opacity-100 opacity-0 transition-all duration-300'>{modelado3d[7].tag}</p>
                                </div>
                                <div className="row-span-2 col-span-2 h-[324px] group relative">
                                    <img src={modelado3d[8].imageUrl} className='object-cover w-full h-full wow animate__animated animate__fadeIn' data-wow-duration="3s" alt={modelado3d[8].titulo} />
                                    <p className='bg-black px-3 py-1 text-white w-auto absolute top-0 left-0 group-hover:opacity-100 opacity-0 transition-all duration-300'>{modelado3d[8].tag}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative w-full bg-gray-800 text-center text-white pt-6 pb-2 mb-10 md:mb-20">
                <div>
                    <div className="absolute inset-0 bg-gray-800 transform skew-y-2 origin-top-left"></div>
                    <h2 className="relative z-10 text-2xl lg:text-4xl uppercase wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                        <span dangerouslySetInnerHTML={{ __html: data?.titulo_modelado }}></span>
                    </h2>
                </div>
            </div>
            <div className="relative pb-[96.25%] md:pb-[56.25%] h-0 w-[1200px] mx-auto max-w-full">
                <iframe
                    src="https://3dvox.com/models/canberra/departamento_603/index.html"
                    frameBorder="0"
                    allowFullScreen
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    className="absolute top-0 left-0 w-full h-full"
                    style={{ border: 0 }}
                    title="3D Model"
                ></iframe>
                </div>
        </div>
    );
};

export default Tour;